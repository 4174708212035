import styled from '@emotion/styled';
import { Link } from '@pafcloud/base-components';
import { Color, Font, FontTextSize, deviceWithHover } from '@pafcloud/style';

export const HeaderMenuLink = styled(Link)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',

  fontFamily: Font.Primary,
  fontSize: FontTextSize.Huge,
  fontWeight: 800,
  fontStyle: 'italic',
  textRendering: 'optimizeLegibility',
  textTransform: 'uppercase',
  color: Color.TextColors.MutedText,
  outline: 'none',

  // Hidden bold text to prevent layout shift when hovering.
  '::before': {
    display: 'block',
    content: 'attr(title)',
    height: 0,
    overflow: 'hidden',
    visibility: 'hidden',
    fontWeight: 800,
  },

  // Current link
  '&[aria-current="true"]': {
    color: Color.TextColors.BodyText,

    // Current Tab decoration
    '::after': {
      content: '""',
      position: 'absolute',

      right: -4,
      bottom: 0,
      left: -4,
      height: 2,
      zIndex: -1,
      width: 'auto',
      background: Color.Primitive.Primary,
    },
  },

  [deviceWithHover]: {
    ':hover': {
      color: Color.TextColors.BodyText,
      fontWeight: 900,
    },

    '&:focus-visible::after': {
      background: Color.Primitive.Primary,
      width: 'auto',
    },
  },
});
