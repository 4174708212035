import LogoNoText from './svg/oneone-logo-no-text.svg';
import LogoHorizontal from './svg/oneone-logo-horizontal.svg';
import LogoHorizontalInverted from './svg/oneone-logo-horizontal-inverted.svg';
import type { LogotypeType } from './logo-variants-types';

export const Logos: LogotypeType = {
  Horizontal: LogoHorizontal,
  HorizontalInverse: LogoHorizontalInverted,
  Vertical: LogoNoText,
  VerticalInverse: LogoNoText,
  Icon: LogoNoText,
  IconInverse: LogoNoText,
};
