import { createTransparentColor } from '../create-transparent-color';
import type { ColorDefinition } from './color-types';

const MomentumPalette = {
  Carbon: '#1A1C21',

  // Primary Colors
  Red: '#EF0E39',
  RedDarker: '#E3092E',

  // Gradients
  GradientStart: '#EF0E39',
  GradientEnd: '#E53535',

  Black: '#000000',
  Darker: '#121316',
  Dark: '#25272d',
  Light: '#cdcbcb',
  Lightest: '#E0E2EA',

  Green: '#3fb950',
  Yellow: '#FFC659',
  Error: '#ff3333',

  Neutral: '#85858A',
  White: '#ffffff',
};

const Primitive: ColorDefinition['Primitive'] = {
  Primary: MomentumPalette.Red,
  PrimaryTint: MomentumPalette.RedDarker,
  PrimaryContrast: MomentumPalette.White,

  Secondary: MomentumPalette.White,
  SecondaryTint: MomentumPalette.Lightest,
  SecondaryContrast: MomentumPalette.Darker,

  Accent: MomentumPalette.Red,
  AccentTint: MomentumPalette.RedDarker,
  AccentContrast: MomentumPalette.White,

  Gradient: `linear-gradient(86deg, ${MomentumPalette.GradientStart}, ${MomentumPalette.GradientEnd}) border-box`,
  GradientTint: `linear-gradient(86deg, ${MomentumPalette.RedDarker}, ${MomentumPalette.GradientEnd}) border-box`,
  GradientContrast: MomentumPalette.White,
};

const TextColors: ColorDefinition['TextColors'] = {
  HeadingText: MomentumPalette.White,
  BodyText: MomentumPalette.White,
  MutedText: MomentumPalette.Light,
  HighlightedText: MomentumPalette.White,
  LinkText: MomentumPalette.Yellow,
  ErrorText: MomentumPalette.Yellow,
  DisabledText: MomentumPalette.Neutral,
};

const Surface: ColorDefinition['Surface'] = {
  Base: {
    Background: MomentumPalette.Black,
    Foreground: MomentumPalette.White,
    Dimmed: createTransparentColor(MomentumPalette.White, 0.25),
  },
  Nested: {
    Background: MomentumPalette.Dark,
    Foreground: MomentumPalette.White,
    Dimmed: createTransparentColor(MomentumPalette.White, 0.15),
  },
  Disabled: {
    Background: MomentumPalette.Neutral,
    Foreground: MomentumPalette.Dark + '80',
    Dimmed: createTransparentColor(MomentumPalette.Darker, 0.15),
  },
  Floating: {
    Background: MomentumPalette.Darker,
    Foreground: MomentumPalette.White,
    Dimmed: createTransparentColor(MomentumPalette.White, 0.15),
  },
};

const Signal: ColorDefinition['Signal'] = {
  Success: MomentumPalette.Green,
  SuccessContrast: MomentumPalette.White,

  Info: MomentumPalette.Lightest,
  InfoContrast: MomentumPalette.Darker,

  Attention: MomentumPalette.Yellow,
  AttentionContrast: MomentumPalette.Darker,

  Danger: MomentumPalette.Error,
  DangerContrast: MomentumPalette.White,
};

const CornerRadius: ColorDefinition['CornerRadius'] = {
  Small: '4px',
  Base: '12px',
  Large: '12px',
};

const Elevation: ColorDefinition['Elevation'] = {
  Level1: `0 3px 12px 0 var(--shadow-color, ${createTransparentColor(MomentumPalette.Black, 0.15)})`,
  Level2: '0 4px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level3: '0 8px 12px var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: ColorDefinition['General'] = {
  Bonus: MomentumPalette.Red,
  BonusContrast: MomentumPalette.White,
};

const Layout: ColorDefinition['Layout'] = {
  Background: MomentumPalette.Carbon,
  BrandBorder: Primitive.Gradient,
  BrowserTheme: Primitive.Primary,
  LoadingColor: Primitive.Primary,
  Overlay: MomentumPalette.Black + 'CC',
  Sidebar: {
    Background: `linear-gradient(0deg, ${MomentumPalette.Black}, ${MomentumPalette.Darker})`,
    Foreground: MomentumPalette.Lightest,
  },
};

const Hero: ColorDefinition['Hero'] = {
  Heading: MomentumPalette.White,
  Text: MomentumPalette.White,
  TextHighlight: MomentumPalette.White,

  Button: {
    Solid: MomentumPalette.White,
    Rest: MomentumPalette.White,
    Hover: MomentumPalette.Lightest,
    Active: MomentumPalette.Lightest,
    Text: MomentumPalette.Black,
  },
};
export const Color: ColorDefinition = {
  TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,
};
