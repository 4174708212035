import type { FC } from 'react';
import styled from '@emotion/styled';

const Burger = styled.span({
  height: 18,
  width: 24,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  transform: 'skewX(-9deg)',

  'span, span::after, ::after, ::before': {
    content: '""',
    position: 'relative',
    display: 'block',
    height: 2,
    width: '100%',
    background: 'currentColor',
    transition: 'transform 0.1s ease-in-out',
    transformOrigin: 'center right',
  },

  '::after': {
    width: '100%',
    alignSelf: 'flex-end',
    transform: 'scaleX(0.7)',
  },

  span: {
    alignSelf: 'flex-end',
  },

  ':hover': {
    span: {
      transform: 'scaleX(0.8)',
    },
    '::after': {
      transform: 'scaleX(0.6)',
    },
  },
});

type BurgerProps = {
  open: boolean;
  className?: string;
};

export const BurgerComponent: FC<BurgerProps> = ({ className }) => {
  return (
    <Burger className={className}>
      <span />
    </Burger>
  );
};
