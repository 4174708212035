import { Color } from '@pafcloud/style';

export const Colors = {
  Text: Color.TextColors.MutedText,
  TextHover: Color.TextColors.BodyText,
  TextActive: Color.TextColors.BodyText,
  TextShadow: undefined,
  TextTransform: undefined,

  Icon: Color.TextColors.MutedText,
  IconActive: 'currentColor',

  BackgroundHover: undefined,
  BackgroundActive: undefined,

  ItemDivider: undefined,
  ItemActiveShadow: undefined,

  SubMenuBackground: Color.Surface.Base.Background,
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: 'uppercase',
  Weight: 900,
  WeightHover: 900,
  WeightActive: 900,
} as const;
